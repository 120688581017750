import abi from '../contracts/vv.json';
import { ethers } from 'ethers';
import { isMetaMaskInstalled, ethereum } from '../config';

import { estimateFees } from '@mycrypto/gas-estimation';
import { formatUnits, parseUnits } from "ethers/lib/utils";

import { GasPriceOracle } from "gas-price-oracle";

const contractsAddr = {
    mainnet: "0x1f607c8fd365444b1a4f0e73d1a863effec6e7be",
    goerli: "0x7C966E1D514FF6C30229dE2fD2b7f0F658d6C8C1"
}

const chainCfg = {
    mainnet: { chainId: 1, defaultRpc: "https://eth-mainnet.g.alchemy.com/v2/bgtfCGSimMMtYejDWDLYZ2E9kLd1_aZE" },
    goerli: { chainId: 5, defaultRpc: "https://eth-goerli.g.alchemy.com/v2/OqT8rPF1dIFY3bSnICswJLwCbxpQL2VN" }
}

const env = "mainnet"

const pricePerToken = 0.01;


const getGasPrices = async () => {
    const oracle = new GasPriceOracle(chainCfg[env])

    let gasPrices = await oracle.eip1559.estimateFees();

    for (const k in gasPrices) {
        gasPrices[k] = parseUnits(gasPrices[k].toString(), 'gwei');
    }

    return gasPrices
}


export const mint = async (mint_amount) => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractsAddr[env], abi, signer);

        const web3 = new ethers.providers.Web3Provider(window.ethereum)

        const { maxFeePerGas, maxPriorityFeePerGas } = await getGasPrices(web3);

        //let feeData = await web3.getFeeData()

        // {
        //   gasPrice: { BigNumber: "14184772639" },
        //   lastBaseFeePerGas: { BigNumber: "14038523098" },
        //   maxFeePerGas: { BigNumber: "29577046196" },
        //   maxPriorityFeePerGas: { BigNumber: "1500000000" }
        // }
        // console.log("fees", formatUnits(maxFeePerGas, "gwei"), formatUnits(maxFeePerGas, "wei"));
        //

        const price = ethers.utils.parseEther((pricePerToken * mint_amount).toString());

        let txDetails = {
            maxFeePerGas: maxFeePerGas,
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            value: price
        };

        let gasLimit = await nftContract.estimateGas.mint(mint_amount, txDetails);
        txDetails.gasLimit = Math.round(gasLimit * 1.15).toString()

        console.log(`Contract: ${contractsAddr[env]} price: ${price} amount: ${mint_amount} gasLimit: ${txDetails.gasLimit} gwei fees: ${formatUnits(maxFeePerGas, "gwei")}`);

        let txnHash = await nftContract.mint(mint_amount, txDetails)

        return txnHash
    }
}

export const totalMintCount = async () => {
    if(isMetaMaskInstalled()){
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractsAddr[env], abi, signer);

        let totalMint = await nftContract.totalSupply();

        return totalMint;
    }
}
