import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "The Virtual Vandals are a secretive and elusive group, and their true identities are unknown. A group of rogue degens who have banded together to disrupt the web2 world.  Each piece in the collection is an expression of the Vandals' disdain for the status quo and their desire to push the boundaries of what web3 can be. Owning one of the Virtual Vandals makes you part of the movement, an accomplice of the rebellion, and a patron of the digital revolution Web3 brings.",
  aboutDescription2:
    '',
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Berneice Tran",
      role: "Artist",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Leana Rosa",
      role: "Co-Artist",
    },
  ],
};

export default data;
