import thumb1 from "../images/nft/cat1.png";
import thumb2 from "../images/nft/cat2.png";
import thumb3 from "../images/nft/cat3.png";

const data = [
  {
    thumb: thumb1,
    featuredText: "",
    title: "Single Mint",
    accessText: "",
    unitPrice: "0.01 ETH",
    amount: 1,
    totalPrice: "0.01 ETH",
    btnText: "Mint 1x",
  },
  {
    thumb: thumb2,
    featuredText: "Gas Savings",
    title: "3x Mint",
    accessText: "VIP channel access",
    unitPrice: "0.01 ETH",
    amount: 3,
    totalPrice: "0.03 ETH",
    btnText: "Mint 3x",
  },
  {
    thumb: thumb3,
    featuredText: "Gas Savings",
    title: "5x Mint",
    accessText: "VIP channel access",
    unitPrice: "0.01 ETH",
    amount: 5,
    totalPrice: "0.05 ETH",
    btnText: "Mint 5x",
  },
];

export default data;
